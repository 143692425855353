.dispensary_list_item {
  display: flex;
  justify-content: space-between;
  border: 1px solid lightgrey;
  border-radius: 10px;
  margin: 0 10px 0 10px;
}

.dispensary_locations {
  display: flex;
  flex-direction: row;
  padding: 2rem;
}

.dispensary_edit_btn {
  border-radius: 10px;
  padding: 0 10px 0 10px;
  font-size: 1rem;
  font-weight: bold;
  background-color: #acbebb;
}
.signup-checkbox {
  font-size: 1.25rem;
  font-weight: bold;
}
br {
  padding: 0;
  margin: 0;
}
.bullets {
  list-style-type: disc;
  text-align: left;
}
.bullets-nums {
  text-align: left;
}

@media (min-width: 250px) {
  .merp-logo-list {
    width: 100px;
    height: 100px;
  }
  .brand_list_section {
    font-size: 0.75rem;
    padding: 1rem;
  }
  .brand_list_form {
    font-size: 1rem;
  }
}

@media (min-width: 350px) {
  .merp-logo-list {
    width: 150px;
    height: 150px;
  }
  .brand_list_section {
    font-size: 1.5rem;
    padding: 1rem;
  }
  .brand_list_form {
    font-size: 1.5rem;
  }
  .brand_list_section_footnote {
    font-size: 0.75rem;
    padding: 1rem;
  }
  .brand_list_main_header_footnote {
    text-align: center;
    font-size: 1rem;
    font-weight: bold;
  }
}

@media (min-width: 400px) {
  .brand_list_section {
    font-size: 1.5rem;
    padding: 1.5rem;
  }
  .brand_list_form {
    font-size: 1.5rem;
    padding: 1.5rem;
  }
  .brand_list_section_footnote {
    font-size: 0.75rem;
    padding: 1rem;
  }
  .brand_list_main_header_footnote {
    text-align: center;
    font-size: 1rem;
    font-weight: bold;
  }
  .brand_list_section_footnote {
    font-size: 1rem;
    padding: 1.25rem;
  }
  .brand_list_main_header_footnote {
    text-align: center;
    font-size: 1.25rem;
    font-weight: bold;
  }
}

@media (min-width: 540px) {
}

@media (min-width: 750px) {
  .merp-logo-list {
    width: 200px;
    height: 200px;
  }
  .brand_list_section {
    font-size: 1.75rem;
    padding: 0.75rem;
  }
  .brand_list_section_footnote {
    font-size: 1.25rem;
    padding: 2rem;
  }
  .brand_list_main_header_footnote {
    text-align: center;
    font-size: 1.25rem;
    font-weight: bold;
  }
  .brand_list_form {
    font-size: 1.75rem;
  }
}

@media (min-width: 815px) {
}

@media (min-width: 900px) {
  .merp-logo-list {
    width: 300px;
    height: 300px;
  }
  .brand_list_section {
    font-size: 2rem;
    padding: 1rem;
  }
  .brand_list_section_footnote {
    font-size: 1.5rem;
    padding: 2rem;
  }
  .brand_list_main_header_footnote {
    text-align: center;
    font-size: 1.75rem;
    font-weight: bold;
  }
  .brand_list_form {
    font-size: 2rem;
  }
}

@media (min-width: 1200px) {
  .merp-logo-list {
    width: 300px;
    height: 300px;
  }
  .brand_list_section {
    font-size: 1.5rem;
    padding: 1rem;
  }
  .brand_list_section_footnote {
    font-size: 1.25rem;
    padding: 2.5rem 20rem 2.5rem 20rem;
  }
  .brand_list_main_header_footnote {
    text-align: center;
    font-size: 1rem;
    font-weight: bold;
  }
  .brand_list_form {
    font-size: 1.5rem;
  }
}

@media (min-width: 1500px) {
}

@media (min-width: 1650px) {
}
