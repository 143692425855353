.verify-button{
    border-radius: 22px;
    background-color: rgb(244, 227, 171);
    color: grey;
    margin: 10%;
    padding: 2.5% 10% 2.5% 10%;

}

.age-verify{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-content: center;
    padding:10%
}
.age-verify-text{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    padding:10%
}

.button-div{
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-content: center;
    
}