/* header{
    margin-top:60px;

} */
/* 
.headers-title {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: "Bree Serif", serif;
  columns: #444;
} */
.text {
  color: white;
  font-size: 10rem;
  font-family: "Bree Serif", serif;

  /* display: flex;
  justify-content: center;
  align-items: center; */
}

.hero-inner {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  text-align: center;
  margin: 10% 0;
 
 
}
.hero {
  background-image: url("./images/3.png");
  background-position: center top;
  background-size: 100%;
  background-repeat: no-repeat;
  object-fit: cover;
  height: auto;
}
/* .headers-title-sm {
  position: relative;
  top: 18%;
  font-size: 20px;
} */

/* .headers-title-lg {
  position: relative;
  top: 50%;
  left: 0;
  padding: 1rem;
  transform: translateY(-50%);
  color:black
}

.headers-img {
  width: 100%;
  height: 450px;
  margin-top: 80px;
  object-fit: cover;
} */
