.featured-product-title {
  font-family: "Epilogue", sans-serif;
  font-weight: 600;
}

small {
  font-family: "Raleway", sans-serif;
  font-weight: 400;
}

.contact-field {
  display: flex;
  flex-direction: column;
}

.home-categories {
  display: flex;
  justify-content: center;
}

.features-text {
  font-family: "Raleway", sans-serif;
  font-size: 1.5rem;
}

.cont .feat {
  padding: 0.5% 10%;
  
}

.contact-when-near-input {
  margin: 1rem;
}

.featured-product {
  justify-content: center;
  align-items: center;
  flex-direction: row;
}

.answered-q-title {
  font-size: 2rem;
  font-family: "Oswald", sans-serif;
}

.submitted-q-text {
  font-family: "Raleway", sans-serif;
}
.contact-when-near-title {
  padding-top: 1.5rem;
  padding-bottom: 5px;
  margin-bottom: 0;
  font-size: 2.25rem;
  font-family: "Oswald", sans-serif;
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}

.img_cont img {
  padding: 10px;
}
.contact-when-nearme-text {
  display: flex;
  font-size: 2rem;
  padding-right: 0;
  padding-top: 0;
  justify-content: center;
  margin-top: 0;
  font-family: "Oswald", sans-serif;
  font-weight: 300;
}
.mission-subtitle {
  font-size: 2.5rem;
}

.mission-sub-header {
  font-size: 1.75rem;

  /* font-family: "Oswald", sans-serif; */
  font-weight: 300;
}
.featured-title {
  font-size: 2.75rem;
  font-weight: bold;
}

.featured-text {
  font-size: 1.5rem;
}

.featured-btn {
  border-radius: 17px;
  padding: 8px;
  font-size: 1.25rem;
}
.featured-img{
    max-width: 500;
}
.react-multi-carousel-track li{
  display: flex;
  justify-content: center;
align-items: center;


}

.slider-slide-cont{
  display: flex;
  justify-content: center;
  align-items: center;
}

/* .react-multi-carousel-track li  .img_cont{
  width:100%;

} */
.react-multi-carousel-track li .slider-img{
  width:100%;
 
  border-radius:18px
}

.react-multi-carousel-dot button{
  display: inline-block;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  opacity: 1;
  padding: 5px 5px 5px 5px;
 
  
  border-width: 1px !important;
  border-style: solid;
  border-color: pink;
 
} 

.react-multi-carousel-dot-list{
  padding: 3rem;
}


.slider-cont{
  width: 100%;
  margin:auto
}

@media (min-width: 786px) {
  .featured-text {
    font-size: 1.5rem;
  }
  .features-text {
    font-size: 2.5rem;
  }
  .slider-cont{
    max-width: 100rem;
    margin:auto
  }
    .input-label{
        font-size: 3rem;
    }
  .contact-when-near-title {
    font-size: 4.5rem;
  }
  .contact-when-nearme-text {
    font-size: 3.5rem;
    font-weight: 400;
  }
  .mission {
    margin-top: 2rem;
    margin-bottom: 2rem;
  }
  .mission-subtitle {
    font-size: 4.5rem;
  }
  .mission-sub-header {
    font-size: 3.75rem;

    /* font-family: "Oswald", sans-serif; */
    font-weight: 300;
  }
  .mission-btn {
    font-size: 2rem;
    font-weight: 500;
    border-radius: 30px;
    padding: 1.5rem;
  }
  .featured-title {
    font-size: 3.75rem;
    font-weight: bold;
  }

  .features-title {
    font-size: 4.25rem;
    padding-top: 1rem;
  
  }
  .featured-text {
    font-size: 2.75rem;
  }
  .featured-btn {
    border-radius: 25px;
    padding: 15px;
    font-size: 2.25rem;
  }
  
}
@media (min-width: 1007px) {
  .cont .feat {
    padding: 0.5% 25%;
    
  }
    .featured-img{
        max-width: 450;
    }
  .mission-subtitle {
    font-size: 3rem;
  }
  .mission-sub-header {
    font-size: 3rem;

    /* font-family: "Oswald", sans-serif; */
    font-weight: 300;
  }

  .mission-btn {
    font-size: 1.5rem;
  }
  .input-label{
    font-size: 2rem;
}
.contact-when-near-title {
font-size: 3.5rem;
}
.contact-when-nearme-text {
font-size: 2.5rem;
font-weight: 400;
}
.featured-title {
    font-size: 2.75rem;
    font-weight: bold;
  }
  .featured-text {
    font-size: 1.75rem;
  }
  .featured-btn {
    border-radius: 25px;
    padding: 15px;
    font-size: 1.75rem;
  }
}

@media(min-width:1200px){
  .features-text {
    font-size: 1.75rem;
  }

  .features-title {
    font-size: 3.75rem;
    padding-top: 1rem;
  
  }
}
