.blogs{
    display: flex;
flex-direction: column;
justify-content: center;

    
}

.post-create-icon{
    display: flex;
    color: #666666;
    font-size: 2.5rem;
    justify-content: flex-end;
    padding-right: 1.5rem;
}

.posts-container{
    display: flex;
    justify-content: space-evenly;
    max-width: 100rem;
}