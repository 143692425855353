.post-page{
    display: flex;
    flex:12;
    justify-content: center;
}
.post-page-description{
  font-size: 1.5rem;
padding:1.5rem;
text-align: center;
/* max-width: 85%; */
}
.post-page-col-wrapper{
   
    display: flex;
    justify-content: center;
    flex-direction: column;
}

.post-page-img{
    /* width: 100%;
    height:auto; */
    border-radius: 5px;
    /* object-fit: cover; */
}

.post-page-title{
    display: flex;
    justify-content: center;
    flex-direction: row;
    text-align: center;
    margin: 10px;
    margin-left: 0;
    font-family: 'Bree Serif', serif;
    font-size: 28px 

}



.post-page-edit{
float: right;
font-size: 16px;
}

.post-page-icon{
    margin-left: 10px;
    cursor: pointer;
}


.post-page-icon:first-child{
    color:#C4C4C4
}




.post-page-icon:last-child{
    color:tomato;
}


.post-page-info {
    margin-bottom: 20px;
    display: flex;
    justify-content: center;
    font-size: 16px;
    color: #D89947;
    font-family: 'Raleway', sans-serif;
}


.post-page-description{
    /* color:#666666; */
    display: flex;
    list-style-type: circle;

    /* justify-content: center; */
    /* align-content: center; */
    flex-direction: column;
    font-family: 'Epilogue', sans-serif;
    

}

.post-page-description{
    font-size: 1.5rem;
    
}


.centering-video{
    padding:5rem;
}


.post-page-description::first-letter{
    margin-left: 20px;
    font-size:30px;
    font-weight: 600;
}

.blog-title{
    font-size:4.5rem !important;
    font-weight: 600;
    font-family: 'Oswald', sans-serif;
    display: flex;
    justify-content: center;
font-weight: 400;
}
.img-container{
    max-width: 250px;
    margin-top: 30px;

}

.img-blog-banner{
    max-width: 250px;

}

@media (min-width:750px){
    .post-page-description{
        font-size: 2rem;
    }
}


@media (min-width:1200px){
    .post-page-description{
        text-align: left;
      max-width: 80rem;
      padding:unset
      }
      .img-blog-banner{
        max-width: 500px;
    
    }

    .post-page-info {
      
        font-size: 20px;
       
    }
    
}


