@media (min-width: 350px) {
  .rewards-list {
    font-size: 1.5rem;
  }
  .rewards-info-container {
    text-align: center;
    max-width: 85%;
  }
  .reward-info-header {
    font-size: 2.5rem;
    font-weight: 600;
  }
  .reward-info-subtext {
    font-size: 1.75rem;
  }
  .rewards-list-header {
    font-size: 1.5rem;
    font-weight: 600;
  }
  .rewards-info-content {
    padding-left: 3rem;
    padding-right: 3rem;
  }

  #rewards-details {
    font-size: 2.5rem;
  }
}

@media (min-width: 400px) {
  .rewrads-info-subtext {
    font-size: 1.75rem;
  }
}

@media (min-width: 540px) {
  .rewards-info-content {
    padding-left: 7rem;
    padding-right: 7rem;
  }
}

@media (min-width: 750px) {
  .rewards-info-content {
    margin-left: 3rem;
    margin-right: 3rem;
    padding-left: 9rem;
    padding-right: 9rem;
  }
  .rewards-list-header {
    font-size: 1.75rem;
  }

  .rewards-list {
    font-size: 1.5rem;
  }
}

@media (min-width: 815px) {
  .reward-info-header {
    font-size: 3.5rem;
  }
  .rewards-list-header {
    font-size: 3rem;
  }
  .rewards-list {
    font-size: 2.75rem;
  }
  .reward-info-subtext {
    font-size: 3rem;
  }
  #rewards-details {
    font-size: 3.5rem;
  }
  .rewards-available-title {
    font-size: 2rem;
  }
  .rewards-items {
    font-size: 1.5rem;
  }
}

@media (min-width: 900px) {
  .reward-info-subtext {
    font-size: 2.5rem;
  }
  .reward-info-header {
    font-size: 3.5rem;
  }
  .rewards-list-header {
    font-size: 2.5rem;
  }
  .rewards-list {
    font-size: 2rem;
  }

  .rewards-info-content {
    margin-top: 4rem;
  }
}

@media (min-width: 1200px) {
  .rewards-info-content {
    padding-left: 18rem;
    padding-right: 18rem;
  }
}

@media (min-width: 1500px) {
  .rewards-info-content {
    padding-left: 30rem;
    padding-right: 30rem;
  }
}

@media (min-width: 1650px) {
  .rewards-info-content {
    padding-left: 40rem;
    padding-right: 40rem;
  }
}

#rewards-details {
  font-family: "Oswald", "serif";
}

.rewards-available-title {
  font-family: "Oswald", "serif";
  margin-bottom: 10px;
}
.rewards-section {
  margin: 20px 0 20px 0;
}
.reward-img {
  max-width: 50px;
  max-height: 50px;
}

.reward-card {
  display: flex;
  align-items: center;
  flex-direction: row;
  font-size: 1.5rem;
  font-weight: bold;
  margin: 1.5rem 0 1rem 0;
  padding: 2rem;
  border: 1px solid transparent;
  border-radius: 10px;
  background-color: #fff9e5;
}

.redeem-btn {
  font-size: 1.25rem;
  font-weight: bold;
  border: 1px solid transparent;
  background-color: #c2cc96;
  color: rgb(38, 38, 38);
  border-radius: 15px;
  padding: 6px;
}

.size-dropdwn {
  border: transparent solid 1px;
  border-radius: 10px;
}
