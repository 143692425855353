.dispensary_page {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-content: center;
}

.brand_row {
  justify-content: space-evenly;
  align-items: center;
  border-bottom: 1px solid rgb(214, 214, 214);
  padding-bottom: 0.75rem;
  margin-bottom: 2rem;
}
.brand-section-title {
  font-family: "Raleway", sans-serif;
  font-size: 3rem;
  text-align: center;
  font-weight: bold;
}
.subtext {
  font-size: 1.75rem;
}

.favorite-button {
  display: flex;
  flex-direction: row;
  max-width: 70%;
  align-items: center;
  justify-content: flex-end;
  margin-top: 0.95rem;
  font-size: 2rem;
  font-weight: bold;
}

.favorite-button > img {
  height: 3rem;
  width: 3rem;
  margin-right: 0.5rem;
}

.brand-section {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-top: 4rem;
}

.search-header {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.search-header-text {
  text-align: center;
  max-width: 60%;
  font-size: 1.25rem;
}

@media (min-width: 700px) {
  .review-card-text {
    font-size: 1.5rem;
  }
}
