.write{
    /* padding-top: 50px; */
}

.write-form{
position: relative;
}

.write-form-group{
    margin-left: 150px;
    display: flex;
    align-items: center;
   
}

.write-icon{
    width: 25px;
    height:25px;
    border-radius: 50%;
    border: 1px solid;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 20px;
    color: rgb(121,118,118);
    cursor: pointer;
}

.post-page-icon{
    color:tomato;
}

.youtube-vids{
    margin-top: 20px;
margin-bottom: 20px;
}

.youtube-vid{
    margin-top: 10px;
    margin-bottom: 10px;
}

.blog-stock-img{
    width:15rem
}

.write-input{
    font-size: 30px;
    border:none;
    padding: 20px;
    width:70vw;
}

.write-input:focus{
    outline:none
}

.write-text{
    font-size: 20px;
    height: 100vh;
    /* In case the text is larger than the boxes height */
    overflow-y: auto;
}

.write-submit{
    position: absolute;
    top:20px;
    right:50px;
    color: white;
    padding: 10px;
    background-color: teal;
    border: none;
    border-radius: 10px;
    cursor: pointer;
    font-size: 16px;
}

.write-img{
    /* margin-left: 150px;
    width:70vw;
    height:250px; */
    border-radius: 10px;
    object-fit: cover;

}
.blog-categories{
    padding:5rem
}

.blog-title{
    padding-bottom:1.5rem;
    font-size: 2.5rem;
}






