.post{
  display:flex;
  flex-direction: row;
  flex-wrap: wrap-reverse;
  align-items: center;
  min-width: 300px;
  justify-content: center;
  background-color: rgba(235, 232, 232, 0.668);
  border-radius: 12px;;
  margin:2rem;
  padding:2rem

}

a:hover{
    text-decoration: none;
}
.post-img{
    max-width: 130px;
    max-height: 130px;
    /* height: 280px; */
  
    border-radius: 7px;
    display: flex;
    justify-content:flex-end;
}

.post-info{
   text-align: center;
 
   width: 100%;
  
}

.post-categories{
    font-family: 'Raleway', sans-serif;
    font-size:11px;
    color: black;
    text-align: center;

    margin-bottom: 10px;

    line-height: 20px;
    margin-top:15px;
    cursor:pointer;
}
#miscellaneous{
    background-color:rgba(219, 140, 237, 0.911) 
}

#politics{
    background-color:rgb(198, 190, 238) ;
}
#media{
background-color: rgb(212, 237, 49);
}

#products{
    background-color: rgb(149, 198, 251);
    
}
#business{
    background-color: rgb(247, 242, 176);
}

#dispensaries{
    background-color: rgb(255, 158, 168);
}

#food{
background-color: rgb(231, 177, 116);
}
.post-category{
padding:.75rem;
border-radius: 2rem;
margin:.5rem;
text-align: center;
}



.post-title{
    font-family: 'Bree Serif', serif;
    font-size: 24px;
    font-weight: 500;
    margin-top: 15px;
    cursor: pointer;
}

.post-title a{
    color: black;
}
.post-title a:hover{
    color:  #BABC70;
}
.post-date{
    font-family: 'Raleway', sans-serif;
    font-style: italic;
    font-size:1.5rem;
    color:#999;
    margin-top: 5px;
}

.post-description{
    font-family: 'Raleway', sans-serif;
    font-size: 14px;
    color:#444;
    line-height: 24px;
    margin-top: 15px;
    text-align: center;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 4;  
    -webkit-box-orient: vertical;
}

@media (min-width:820px){
    .post{
       
        width: 500px;
        padding: 15px;
        flex-direction: row;
        justify-content: space-between;
      
      }
      .post-info{
        text-align: left;
        padding-right: 2rem;
        max-width:70%;
       
     }

     .post-categories{
        font-family: 'Raleway', sans-serif;
        font-size:11px;
        color: black;
        text-align: left;
    
        margin-bottom: 10px;
    
        line-height: 20px;
        margin-top:15px;
        cursor:pointer;
    }
}