.badges-title{
    font-size: 2.5rem;
}

.badge-subtext{
    font-size: 1.5rem;
}

.badge-info-img{
    width:14rem;
    float:left
}
.header-info-img{
    width:16rem;
}
.badge-description{
    list-style-type: disc;
}

.description{
    float: right;
    padding-left: 3.5rem;

}

.badge-title{
    font-size: 2.25rem; 
    font-weight: bold;
}

.about-content{
    font-size: 1.5rem;
   
}
.badge-description{
    font-size: 1.5rem
}

.badge-description-item{
    display: flex;
    align-items: center;
    flex-direction: row;
    margin-top: 3rem;
}

.sub-list{
    list-style-type: circle;
}

.disclaimer-text{
    padding-top: 20px;
    font-size: 1rem;
}

@media(min-width:520px){
    .badge-description{
        font-size: 2rem;
       
    }
    .about-content{
        font-size: 2rem;
    }
}

@media(min-width:850px){
    .badge-subtext{
        font-size: 2.25rem;
    }
    .badges-title{
        font-size: 2.75rem;
        font-weight: bold;
        margin-top: 2rem;
    }
    
    .header-info-img{
        width:25rem;
    }
    .badge-description{
        font-size: 2.25rem;
       
    }
    .about-content{
        font-size: 2.25rem;
    }

    .badge-disclaimer{
        font-size: 1.5rem;
    }

    .form{
        max-width: 100rem;
    }
    .badge-info-img{
        width:19rem;
        float:left
    }
    .disclaimer-text{
     
        font-size: 1.25rem;
    }
}