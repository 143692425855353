#badges-slide{
    text-align: center;
    padding-left: none;
    /* //changed for badges slide text */
    max-width: 60%;
    margin-left: 2rem;
    font-family: "Oswald", sans-serif;
    font-size: 0.5rem;
}

#overlay-1 {

    /* //changed for badges lside text */
    left:10%
}

