

.post-page-wrapper{
    margin: auto;
    display: flex;
    justify-content: center;
    flex-direction: row;
    flex-wrap: wrap;
}

@media (min-width:820px){
    .post-page-wrapper{
        margin: auto;
        display: flex;
        justify-content: center;
        flex-direction: row;
        flex-wrap: wrap;
        margin-left: 40px;
        margin-right: 40px;
        margin-top: 20px;
        margin-bottom: 20px;
    }
}

@media (min-width:1200px){
   

    .post-page-wrapper{
        justify-content: flex-start;
    } 
}

