* {
  box-sizing: border-box;
}

input,
select,
textarea,
button {
  padding: 0.5rem;
  border-radius: 0.5rem;
  border: 0.1rem #a4a4a4 solid;
  font-size: 1rem;
  font-family: Arial, Helvetica, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #f8f8ff;
  cursor: pointer;
}
.nav-link {
  color: unset;
}
html {
  margin: 0 !important;
  font-size: 62.5%;
  /*16ox x 62.5
= 10px = 1 rem  */
  box-sizing: border-box;
}

html,
body,
#root {
  height: 100%;
}

div {
  padding: 0;
  margin: 0;
}
header {
  font-family: "Raleway", sans-serif;
  top: 0;
  background-color: transparent;
  z-index: 500;
  position: sticky;
}
.page-container {
  min-height: 100%;
  display: flex;
  box-sizing: border-box;
  flex-direction: column;
}

.primary-color {
  color: #d89947;
}

.time-left {
  padding: 2rem 3rem 2rem 3rem;
}
body {
  margin: 0 !important;

  font-family: Arial, Helvetica, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.dropdown-item {
  font-size: 1.5rem;
  color: white;
}
/* .grid-container {
  display: grid;
  grid-template-areas: "header" "main" " footer";
  grid-template-columns: 1fr;
  grid-template-rows: 20rem 1fr 5rem;
  height: 100vh;
} */

.dropdown-menu {
  background-color: #d89947;
  border: unset;
}

.register-form {
  font-family: "Sora", "serif";
}

.grid-container {
  height: 100%;
  /* width: 100%; */

  margin: 0;
  position: relative;
}

.extras a {
  color: white;
}

.contact-socials a {
  color: white;
}

.signup-modal {
  display: flex !important;
  flex-direction: column;
  justify-content: center;

  align-content: center;
  /* align-items: flex-start; */
}
.modal-form {
  text-align: center;
}
.modal-header {
  border-bottom: unset;
  font-size: 3.5rem;
  display: flex;
  justify-content: center;
  font-family: "Roboto Condensed", sans-serif;
}

.modal-text {
  font-family: "Raleway", sans-serif;
}

.modal-img {
  max-width: 10rem;
}

.modal-header button {
  font-size: 3rem;
}
.modal-title {
  font-size: 3rem;
}
.modal-title h4 {
  font-size: unset;
}
.subscribe {
  background-color: gray;
}

.nav-list-brand {
  font-size: 1.5rem;
  font-weight: bold;
}
.nav-left {
  /* padding-left: 5px; */
  margin: 5px 5px 5px 0px;
}

.nav-location-marker {
  width: 15px;
}
.nav-left {
  display: flex;
  flex-wrap: nowrap;
  justify-content: start;
  align-items: center;
}

.nav {
  display: flex;
  height: auto;
  width: 100%;
  margin: 0 auto;

  background-color: #d89947;
  text-align: center;
  padding: 10px;
  align-items: center;
  justify-content: center;
}

.nav-row {
  display: flex;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  padding-left: 10px;
  margin-bottom: 3px;
  width: 100%;
}

.nav-row > li {
  padding-left: 5px;
  padding-right: 5px;
  padding-top: 0;
  margin-top: 0;

  color: white;
}

.search-bar-md {
  display: none;
}

/* .navigation-header{
  display: flex;
    border-bottom: 0.0625rem solid rgb(242, 243, 244);
    height: 2.5rem;
    overflow-x: auto hidden;
    width: 100vw;
} */
.nav-header {
  box-sizing: border-box;
  width: 100%;
}
.nav-header section {
  max-width: 375px;
  width: 100%;
  background-color: rgb(218, 163, 92);
  color: white;
  justify-content: center;
}
.search-header {
  box-sizing: border-box;
  width: 100%;
}
.search-header section {
  max-width: 375px;
  width: 100%;
}
.item {
  font-size: 1rem;
  /* font-weight: bold; */
  font-family: "Epilogue", sans-serif;
}
.item a {
  color: white;
}
.item {
  padding: 12px 16px 13px;
}
.item:not(:last-child) {
  border-right: 1px solid #7e7e7e;
}

.item,
.middle {
  display: inline-block;
  vertical-align: middle;
}
.scroll {
  white-space: nowrap;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: -ms-autohiding-scrollbar;
}
.scroll::-webkit-scrollbar {
  display: none;
}

.link {
  color: white;
}
.category-offerings {
  background-color: #5a4e53;
  color: white;
  display: flex;
  height: auto;
  width: 100%;
  margin: 0 auto;
  text-align: center;
  padding: 10px;
  justify-content: center;
  overflow-x: auto;
  overflow-y: hidden;
  white-space: nowrap;
}

.category-offerings > ul {
  /* DO NOT use justify-content here will cause issues */
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  font-size: 9pt;
  height: auto;
  width: 360px;
}
.category-offerings > ul > li {
  padding-left: 7px;
  padding-right: 7px;
}

.mission-banner {
  display: flex;
  height: auto;
  width: 100%;
  margin: 0 auto;
  background-color: #babc70;
  text-align: center;
  padding: 10px;
  justify-content: center;
}

.mission-section {
  display: flex;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  /* width: 100%; */

  margin: 0 auto;
  background-color: #babc70;
}

.mission-btn {
  border-radius: 20px;
  font-family: "Epilogue", sans-serif;
  padding: 8px;
  margin: 10px;
  border: transparent solid;
  color: rgb(79, 79, 79);
  /* font-weight: 300; */
  font-size: 1rem;
}

.mission {
  padding: 1rem;
  margin: 1rem;
  font-family: "Oswald", sans-serif;

  font-weight: 400;
  font-size: 2rem;
}

.contact-input-field {
  /* width: 150px;
  height: 37px; */

  font-size: 1.5rem;
}
.contact-field {
  width: 80%;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
}

.submit-button {
  display: flex;
  align-items: center;
  border-radius: 20px;
}

.reviews-text {
  font-family: "Raleway", sans-serif;
}

#ex5 {
  display: none;
}

.contact-when-near {
  display: flex;
  height: auto;
  width: 100%;
  background-color: #7989dc;
  color: white;
  flex-direction: column;
  margin: 0 auto;
  text-align: center;
  padding: 10px;
  /* align-items: center;
  justify-content: center; */
}

.more-info-btn {
  display: flex;
  justify-content: space-between;
}
.search-bar > div {
  display: flex;
  height: auto;
  width: 100%;
  margin: 0 auto;
  text-align: center;
  padding: 10px;
  align-items: center;
  justify-content: center;
}

/* shop cards/ homescreen */

.mission {
  /* color: #464141; */
  color: white;
  /* font-size: 1.5rem;
  font-family: 'Epilogue', sans-serif;
  padding-top: 1rem;
  font-weight: bold; */

  width: 100%;
  text-align: center;
}

.call-to-action-btn {
  background-color: white;
  border-radius: 20px;
  margin: 0;
  font-size: 1.5rem;
  font-family: "Raleway", sans-serif;
  color: rgba(2, 2, 2, 0.582);
}

.countdown {
  padding-bottom: 4rem;
  overflow-x: auto;
}

.input-label {
  font-family: "Sora", sans-serif;
  font-size: 1.5rem;
}

.contact-when-near-title {
  padding-top: 1.5rem;
  padding-bottom: 5px;
  margin-bottom: 0;
  font-size: 3.5rem;
  font-family: "Oswald", sans-serif;
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}

.contact-when-nearme-text {
  display: flex;
  font-size: 2rem;

  margin-top: 4px;
  padding-right: 0;
  padding-top: 0;
  justify-content: center;
  margin-top: 0;
  font-family: "Epilogue", sans-serif;
}

.contact-when-nearme-text > p {
  width: 80%;
  margin-top: 0;
}
.input-section {
  display: flex;
  justify-content: center;
}

.contact-when-near-input {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

/* .contact-when-near-button {
  display: flex;
} */

.category-boxes {
  padding: 10px;
  height: auto;
  width: 100%;
  flex-direction: column;
  align-items: center;
  margin: 0;
  /* background-color: magenta; */
  background-color: white;
  text-align: center;
  /* padding: 8px 0px 12px 10px; */
  /* justify-content: center */
}
.container .features {
  padding: 0.5% 25%;
  font-size: 0.9em;
}
.features-title {
  font-size: 3rem;
  padding-top: 1rem;
  font-family: "Epilogue", sans-serif;
  font-weight: 500;
}
#ex4 .p1[data-count]:after {
  position: absolute;
  right: 10%;
  top: 8%;
  content: attr(data-count);
  font-size: 40%;
  padding: 0.2em;
  border-radius: 50%;
  line-height: 1em;
  color: white;
  background: rgba(255, 0, 0, 0.85);
  text-align: center;
  min-width: 1em;
}

.cart {
  font-size: 2rem;
  position: absolute;
  right: 25%;
  top: 22%;
}

.category-box-items {
  justify-content: center;
  margin: 0;
  padding: 15px 0 0 0;
  flex-direction: column;
}

.category-box-title {
  padding-top: 0px;
  padding-bottom: 3px;
  margin-bottom: 0;
  font-size: 13pt;
  font-family: "Sora", sans-serif;
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}

.category-box {
  display: flex;
  height: 300px;
  width: 300px;
  background-color: whitesmoke;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 1rem;
}
/* for homescreen category boxes */
.flex-grid {
  display: flex;
  justify-content: center;
  height: 250px;
  width: 250px;
  flex-wrap: wrap;
}

/* took 1px off each side  for margin  */
.flex-grid-item {
  height: 110px;
  width: 110px;
  margin: 1px;
  background-color: transparent;
}

.flex-grid-item > img {
  width: 105px;
  height: 105px;
  padding: 3px;
}

footer {
  grid-area: footer;
  background-color: #babc70;
  color: white;
  bottom: 0;
  width: 100%;
  font-family: "Raleway", sans-serif;
}

.footer-heading {
  font-family: "Bree Serif", serif;
  font-size: 2rem;
}

.footer-item {
  font-size: 1.25rem;
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.website-footer {
  display: flex;
  height: auto;
  width: 100%;
  margin: 0 auto;
  background-color: #babc70;
  text-align: center;
  padding: 10px;
  justify-content: center;
}

.socials-section {
  display: flex;
  height: auto;
  width: 340px;
  margin: 0 auto;
  background-color: #babc70;
  text-align: center;
  padding: 10px;
  justify-content: center;
}

.nav-logo img {
  height: 3rem;
  width: 3rem;
}

.card-details {
  padding-top: 2rem;
}

.container-category {
  padding-bottom: 4rem;
  padding-top: 4rem;
  background-color: #7989dc;
}

.site-slider-3 {
  position: relative;
  margin-bottom: 5rem;
}

.site-slider-3 .product {
  height: 100%;
  padding: 0em;
}
.site-slider-3 .card-details {
  margin-top: 0.5rem;
  padding: 0 2rem;
}

.rating i {
  color: #babc70;
}

.newseller {
  margin-top: 8rem;
}

.slick-next {
  right: none;
}

.carousel-text {
  color: white;
}

.slick-slide {
  text-align: center !important;
}

.overlay {
  position: absolute;
  height: 100%;
  width: 100%;
  /* display: flex;
  justify-content: center;
  align-items: center; */
  top: 0;
  left: 0;
  /* transition: 0.5s; */

  z-index: 1;
}

/* .overlay:hover {
  opacity: 1;
 } */

.overlay-content {
  color: rgb(255, 255, 255);
  position: relative;
  /* top: 50%;
  left: 50%; */
  top: 30%;
  display: flex;
  justify-content: center;

  align-items: center;
  /* transform: translate(-50%, -50%); */
}

.slider-button {
  border-radius: 20px;
  font-size: 1.25rem;
  font-family: "Epilogue", sans-serif;
  border: transparent solid;
}

/* .img_cont:hover .overlay {
  opacity: 1
 } */

#slide-3 {
  font-size: 2.5rem;
}

#slide-4 {
  font-size: 2.5rem;
}
.img_cont {
  position: relative;
  display: inline-block;
}

.slider-btn-1 {
  max-width: 1000px;
}

.slick-slider {
  margin: auto;
}

.position-top {
  position: absolute;
  top: 45%;
}

/* .site-slider .slider-btn .prev,
.site-slider .slider-btn .next {
  background-color: #BABC70;
  padding: 1rem 1.5rem;
  border-radius: 10rem;
  color: white;
  margin: 0 1rem;
  opacity: 0;
  transition: opacity 1s ease;
}

.site-slider:hover .slider-btn .prev,
.site-slider:hover .slider-btn .next {
  opacity: 1;
} */

.right-0 {
  right: 0;
}

.slider-brand .brands-title {
  font-size: 1.5rem;
  padding: 2rem;
}

.slider-brand {
  position: relative;
  margin: 4rem 0;
}
.site-slider-three .pro-price span {
  color: #babc70;
}

.product .cart .site-btn:hover {
  background-color: gray;
  color: white;
}

/* Footer */
footer h4 {
  padding: 4rem 1rem 0;
}

footer .social i {
  padding: 6px;
  font-size: larger;
}

footer .extras small {
  color: white;
  padding: 0.5rem;
}
.footer .extras {
  padding-bottom: 0.5rem;
}

/* Slick Slide */

.slick-slide img {
  display: unset;
}

footer .follow-us div {
  width: 60%;

  /* height:8rem; */
  /* background:lightblue */
}
footer .follow-us div img {
  padding: 0;
}

.brand-logo > img {
  width: 200px;
  height: 200px;
  /* height:8rem; */
}

.brand-logo {
  display: flex;
  justify-content: center;
  margin-bottom: 3rem;
}

.merp-logo-contact div {
  width: 20%;
  /* height:8rem; */
}

.cart-screen-layout {
  padding: 4rem;
  width: 80%;
  display: flex;
}

.checkout-btn {
  width: 50%;
}

.card {
  border: 0;
}

.product {
  max-width: 300px;
}

.modal-img-screen {
  width: 20rem;
}

.product-name {
  font-family: "Raleway", sans-serif;
  font-weight: 400;
  font-size: 1.75rem;
  color: rgb(99, 100, 62);
}

.buy-now a {
  color: black;
  font-size: 1.25rem;
}

.brand-name {
  font-family: "Raleway", sans-serif;
  font-size: 1.75rem;
}

.brand-name a {
  color: rgb(172, 126, 66);
}

.hr {
  border: 1px dotted gray;
}

/* Media Queries (Mobile first-development) */
@media (min-width: 350px) {
  .overlay-content {
    color: rgb(255, 255, 255);
    position: relative;
    top: 15%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .overlay-content-2 {
    color: rgb(255, 255, 255);
    position: relative;
    top: 25%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  #slide-2 {
    text-align: center;
    padding-left: none;
    max-width: 65%;
    font-family: "Oswald", sans-serif;
    font-size: 0.5rem;
  }

  #slide-3 {
    text-align: left;
    padding-left: 8rem;
    max-width: 80%;
    font-family: "Epilogue", sans-serif;
    font-size: 0.5rem;
  }

  #slide-4 {
    text-align: left;
    padding-left: 8rem;
    max-width: 80%;
    font-family: "Epilogue", sans-serif;
    font-size: 0.5rem;
  }
  .slider-h1 {
    font-size: 1.25rem;
  }
  .slider-h1-2 {
    font-size: 1.5rem;
  }
  .slider-subtext {
    font-size: 1rem;
  }

  .slider-subtext-2 {
    font-size: 1rem;
  }
  .slider-btn {
    padding: 0.5rem;
    border-radius: 20px;
    font-size: 0.75rem;
    font-family: "Epilogue", sans-serif;
    border: transparent solid;
  }
  .slider-btn-2 {
    padding: 0.5rem;
    border-radius: 20px;
    font-size: 0.75rem;
    font-family: "Epilogue", sans-serif;
    border: transparent solid;
  }
  .nav {
    padding: 5px 10px 5px 0px;
  }
  .nav-logo img {
    height: 4.5rem;
    width: 4.5rem;
  }

  .nav-header section {
    max-width: 350px;
  }

  .search-header section {
    max-width: 350px;
  }

  .search-bar > div {
    padding: 0px 0px 10px 0px;
  }
  .nav-header section {
    max-width: 540px;
    width: 100%;
    background-color: rgb(218, 163, 92);
    color: white;
    justify-content: center;
  }

  .search-header section {
    max-width: 540px;
    width: 100%;
  }
  .nav-row li {
    font-size: 1.5rem;
    font-weight: bold;
    padding: 0;
  }

  .nav-row li a {
    padding: 3.5px;
  }
}

@media (min-width: 360px) {
  .navbar-inner {
    justify-content: space-around;
  }
  #slide-2 {
    text-align: center;
    padding-left: none;
    max-width: 65%;
    font-size: 0.5rem;
  }
  .overlay-content {
    top: 30%;
  }
  .overlay-content-2 {
    color: rgb(255, 255, 255);
    position: relative;
    top: 15%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  #slide-3 {
    padding-left: 8rem;
    max-width: 80%;

    font-size: 0.5rem;
  }

  #slide-4 {
    padding-left: 8rem;
    max-width: 80%;

    font-size: 0.5rem;
  }
  .slider-h1 {
    font-size: 1rem;
  }

  .slider-subtext {
    font-size: 0.75rem;
  }

  .slider-subtext-2 {
    font-size: 1rem;
  }
  .slider-h1-3 {
    font-size: 1.5rem;
  }
  .slider-subtext-3 {
    font-size: 0.8rem;
  }

  .slider-btn-2 {
    padding: 0.5rem;
    border-radius: 20px;
    margin-top: 8px;
    font-size: 0.75rem;
    font-family: "Epilogue", sans-serif;
    border: transparent solid;
  }

  .slider-btn {
    padding: 0.5rem;
    border-radius: 20px;
    margin-top: 8px;
    font-size: 0.75rem;
    font-family: "Epilogue", sans-serif;
    border: transparent solid;
  }
}

/* Iphone XR width 414px */

@media (min-width: 400px) {
  .overlay-content {
    top: 30%;
  }
  .slider-h1-2 {
    font-size: 2rem;
  }
  .slider-subtext-2 {
    font-size: 1.15rem;
  }

  .slider-h1-3 {
    font-size: 1.75rem;
  }
}

@media (min-width: 540px) {
  .nav-header section {
    max-width: 600px;
  }

  .search-header section {
    max-width: 600px;
  }

  .nav-row > li {
    font-size: 1.5rem;
    padding: 3.5px;
  }

  .nav-logo img {
    width: 5rem;
    height: 5rem;
  }
}

/* Surface Duo Slider */
@media (min-width: 540px) {
  #slide-3 {
    padding-left: 12rem;
    max-width: 80%;
    font-size: 0.5rem;
  }

  #slide-4 {
    padding-left: 2rem;
    max-width: 70%;
    font-size: 0.75rem;
  }

  .slider-h1 {
    font-size: 1.5rem;
  }
  .slider-h1-2 {
    font-size: 3rem;
  }

  .slider-subtext-2 {
    font-size: 1.5rem;
  }
  .slider-h1-3 {
    font-size: 2.25rem;
  }
  .slider-subtext-3 {
    font-size: 1.25rem;
  }

  .slider-btn-2 {
    padding: 0.75rem;

    font-size: 1.15rem;
  }
  .slider-btn {
    padding: 0.75rem;
    margin-top: 10px;
    font-size: 1rem;
  }
}
/* 
Media queries (Mobile first ) */
@media (min-width: 600px) {
  .cart {
    font-size: 4rem;
  }
  input,
  select,
  textarea,
  button {
    font-size: 2.5rem;
  }
  .navbar-inner {
    justify-content: center;
  }
  .contact-field {
    width: 70%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    align-items: center;
  }

  .contact-input-field {
    /* width: 300px; */
    font-size: 1.5rem;
  }
  .search-bar-md {
    display: none;
    /* width: 315px; */
  }
  .nav-header {
    box-sizing: border-box;
    width: 100%;
  }

  .search-header {
    box-sizing: border-box;
    width: 100%;
  }
  .nav-header section {
    max-width: 1007px;
    width: 100%;
    /* background-color: #d89947;
    color: black; */
    justify-content: center;
  }

  .nav-list-brand {
    padding: 3.5px;
    font-size: 2.5rem;
    font-weight: bold;
  }

  .search-header section {
    max-width: 1007px;
    width: 100%;
    /* background-color: #d89947;
    color: black; */
  }
  .item {
    font-size: 2.5rem;
    font-weight: 400;
  }

  .item {
    padding: 12px 16px 13px;
  }
  .item:not(:last-child) {
    border-right: 1px solid #7e7e7e;
  }

  .item,
  .middle {
    display: inline-block;
    vertical-align: middle;
  }
  .scroll {
    white-space: nowrap;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar;
  }
  .scroll::-webkit-scrollbar {
    display: none;
  }

  .nav-left {
    padding-left: 0px;
    margin: 5px 5px 5px 0;
  }
  .mission-banner {
    display: flex;
    height: auto;
    width: 100%;
    margin: 0 auto;
    background-color: #babc70;
    text-align: center;
    padding: 10px;
    justify-content: center;
  }
  /* input{
    font-size: none
  } */

  .category-offerings {
    display: flex;
    height: auto;
    width: 100%;
    margin: 0 auto;
    background-color: #fff9e5;
    text-align: center;
    padding: 10px;
    justify-content: center;
  }

  .category-offerings > ul {
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    font-size: 11pt;
    /* height: 100%; */
    width: 100%;
    /* margin-left: 10px; */
    margin-right: 10px;
  }

  .category-offerings > ul > li {
    padding-left: 7px;
    padding-right: 7px;
    flex-grow: 1;
  }
  .category-boxes {
    display: flex;
    margin: 0 auto;
    overflow-y: scroll;
    align-items: center;
    padding-top: 8px;
    padding-bottom: 12rem;
    padding-left: 10px;
    height: auto;
    flex-direction: row;
    flex-wrap: wrap;
  }

  .category-box-items {
    justify-content: center;
    margin: 0;
    flex-direction: column;
  }

  .category-box-title {
    padding-top: 0px;
    padding-bottom: 5px;
    margin-bottom: 0;
    font-size: 15pt;
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    flex-direction: row;
    justify-content: center;
  }

  .category-box {
    display: flex;
    height: 330px;
    width: 330px;
    padding: 3px 3px 3px 3px;
    background-color: whitesmoke;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .nav {
    display: flex;
    height: 100px;
    width: 100%;
    margin: 0 auto;
    /* background-color: #7989dc; */
    text-align: center;
    padding: 10px;
    justify-content: center;
  }

  header {
    width: 100%;
  }
  .nav-row {
    display: flex;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
    padding-left: 10px;
    margin-bottom: 3px;
  }

  .nav-row > li {
    padding-left: 2px;
    padding-right: 2px;
    padding-top: 0;
    margin-top: 0;
    font-size: 3rem;

    color: white;
  }

  .contact-when-near {
    display: flex;
    background-color: #7989dc;
    /* height: auto; */
    width: 100%;
    /* justify-content: center; */

    flex-direction: column;
    display: flex;
    margin: 0 auto;
    text-align: center;
    padding: 10px;
  }
  .website-footer {
    background-color: #d89947;
  }

  .socials-section {
    background-color: #d89947;
  }
  .nav-logo img {
    width: 10rem;
    height: 10rem;
  }
  .nav-location-marker {
    width: 15px;
  }
  .nav-row li a {
    padding: 3.5px;
    font-size: 2rem;
    font-weight: bold;
  }
  .nav-list-brand {
    font-size: 2rem;
    font-weight: bold;
  }
}
@media (min-width: 750px) {
  .slider-h1-3 {
    font-size: 3.5rem;
  }
  .slider-subtext-3 {
    font-size: 1.9rem;
  }
  #caro {
    margin-top: 1.5rem;
  }
  .slider-btn {
    font-size: 1.5rem;
  }

  .slider-h1-2 {
    font-size: 4.5rem;
  }
  .slider-subtext-2 {
    font-size: 2.25rem;
  }
  .slider-btn-2 {
    padding: 1rem;
    font-size: 1.5rem;
  }
  .slider-subtext {
    font-size: 1.5rem;
    padding-left: 4rem;
  }

  .slider-h1 {
    font-size: 2.5rem;
    padding-left: 4rem;
  }
}

@media (min-width: 900px) {
  input,
  select,
  textarea,
  button {
    font-size: 1.75rem;
  }
  .search-bar-md {
    display: initial;
    width: 315px;
  }
  .cart {
    font-size: 2.5rem;
  }
  #caro {
    margin-top: 2rem;
  }
  .slider-subtext-3 {
    font-size: 2.25rem;
  }
  .slider-h1-3 {
    font-size: 4.25rem;
  }
  .slider-btn-2 {
    padding: 1.15rem;
    font-size: 2rem;
  }
  .slider-btn {
    padding: 1rem;

    font-size: 1.75rem;
  }
  .slider-subtext-2 {
    font-size: 2.5rem;
  }
  .slider-h1-2 {
    font-size: 5rem;
  }
  .slider-subtext {
    font-size: 1.75rem;
    padding-left: 7.5rem;
  }
  .slider-h1 {
    font-size: 3rem;
    padding-left: 7.5rem;
  }
}
@media (min-width: 1007px) {
  .slider-subtext {
    padding-left: 10rem;
  }

  .overlay-content-2 {
    top: 20%;
  }

  .slider-h1 {
    padding-left: 10rem;
  }
  .nav {
    background-color: #d89947;
  }
  .nav-header {
    box-sizing: border-box;
    width: 100%;
  }
  .search-header {
    box-sizing: border-box;
    width: 100%;
  }

  .search-header section {
    max-width: 1200px;
    width: 100%;

    /* justify-content: center; */
  }

  .nav-header section {
    max-width: 1200px;
    width: 100%;
    background-color: rgb(218, 163, 92);
    color: white;
    text-align: center;
    /* justify-content: center; */
  }
  .nav-logo img {
    width: 8rem;
    height: 8rem;
  }
  .item {
    font-size: 1rem;
    font-weight: bold;
  }

  .link {
    color: white;
  }

  .item {
    padding: 10px 14px 12px;
    font-size: 1rem;
    font-weight: bold;
  }
  .item:not(:last-child) {
    border-right: 1px solid #d89947;
  }

  .item,
  .middle {
    display: inline-block;
    vertical-align: middle;
  }
  .scroll {
    white-space: nowrap;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar;
  }
  .scroll::-webkit-scrollbar {
    display: none;
  }
  .mission-banner {
    background-color: #babc70;
  }

  .website-footer {
    background-color: #babc70;
  }

  .socials-section {
    background-color: #babc70;
  }

  .category-boxes {
    height: 990px;
  }
}

@media (min-width: 1200px) {
  body {
    background-color: unset;
  }

  /* .search-screen{
    width:80%;
    display: flex;
    justify-content: center;
  } */
  .nav-header section {
    max-width: 1500px;
    padding: 0;
    text-align: center;
  }

  .search-header section {
    max-width: 1500px;
    padding: 0;
  }
  .item {
    padding: 10px 14px 12px;
    font-size: 1.5rem;
    font-weight: bold;
  }
  .nav-row > li {
    font-size: 1.5rem;
    font-weight: bold;
    padding: 3.5px;
  }

  .nav-row .dropdown {
    font-size: 1.5rem;
    font-weight: bold;
  }

  .nav-logo img {
    width: 9rem;
    height: 9rem;
  }
  .nav-row li a {
    padding: 0.5rem;
    /* font-size: 2rem; */
  }
}

@media (min-width: 1500px) {
  .nav-header section {
    max-width: 2200px;
    padding: 0px 0px 0px 0px;
  }

  .search-header section {
    max-width: 2200px;
    padding: 0px 0px 0px 0px;
  }
  .nav-header {
    text-align: center;
  }
}

/* // This applies from 0px to 600px */

/* // This applies from 600px onwards */

/* Layout */

main {
  grid-area: main;
}

/* Common */
h1 {
  font-size: 1.8rem;
  padding: 1rem 0;
}

h2 {
  font-size: 1.6rem;
  padding: 1rem 0;
}
a {
  text-decoration: none;
}

a:hover {
  color: orange;
}

ul {
  padding: 0;
  margin: 0;
  list-style-type: none;
}

li {
  margin-top: 1rem;
}

input:hover,
select:hover,
textarea:hover,
button:hover {
  border: 0.1rem #404040 solid;
}
button.primary {
  background-color: #f0c040;
}

button.block {
  width: 100%;
}

button.small {
  font-size: 1.2rem;
}
/*Header*/

header a {
  color: white;
  padding: 1rem;
}
a.brand {
  color: black;
  font-size: 3rem;
  font-weight: bold;
}
.cart-badge {
  background-color: #f02020;
  color: #ffffff;
  border-radius: 50%;
  padding: 0.2rem 0.7rem;
  font-size: 1.4rem;
  margin-left: 0.2rem;
}
.badge {
  /* background-color: #f02020;
  color: #ffffff; */
  border-radius: 50%;
  padding: 0.2rem 0.7rem;
  /* font-size: 1.4rem; */
  margin-left: 0.2rem;
}
.search-row {
  justify-content: space-evenly;
  align-items: center;
  border-bottom: 1px solid rgb(214, 214, 214);
  padding-bottom: 0.75rem;
  margin-bottom: 2rem;
}

.search-filter-criteria {
  border-right: 1px solid rgb(214, 214, 214);
}

.search-categories-dispensaries {
  padding-left: 10px;
}

.search-categories-dispensaries > li {
  font-size: 1.25rem;
}

.button-search {
  margin: 1rem;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  border-radius: 2rem;
}

/* Modal */

/* Modal CSS Finish */

.card-row {
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
}
#unclaimed-button {
  width: 60px;
  height: auto;
  font-size: 10px;
  border-radius: 0.5rem;
  padding: 0.5rem;
  color: black;
}

#claimed-button {
  width: 50px;
  height: auto;
  font-size: 10px;
  border-radius: 0.5rem;
  padding: 0.5rem;
  color: black;
}

/* .row-product {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  align-items: left;
} */

.row-cart {
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
}
.placeorder-info ul {
  width: 100%;
}

.place-order-btn {
  border: 1px solid lightgray;
  padding: 1rem;
  border-radius: 15px;
  background-color: #7989dc;
  color: #fff9e5;
  font-size: 1.25rem;
  font-family: "Sora", "serif";
}
.modal.show .modal-dialog {
  transform: none;
  width: 80% !important;
}

.moadl-open {
  padding-right: 0;
}
.review-card {
  padding: 1rem 0 1rem 0;
  border: solid 1px transparent;
  border-radius: 10px;
  background-color: #faf8e8;
}
.review-card-text {
  font-size: 1.5rem;
}
.review-card-name {
  font-size: 1.75rem;
}
.review-card-date {
  font-size: 1.25rem;
}
.row-team {
  display: flex;
  flex-wrap: wrap;
  justify-content: center /* justify-content:stretch ; */;
}

.row-nowrap {
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
}

.row-nowrap-trash {
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
}

.row-nav {
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  /* align-items: center; */
}

.row.top {
  align-items: flex-start;
}

.col-1 {
  flex: 1 1 25rem;
}

.col-2 {
  flex: 2 1 50rem;
}
.col-3 {
  flex: 32 1 75rem;
}

.row.center {
  justify-content: space-evenly;
}
.row-start {
  justify-content: flex-start;
}

.p-1 {
  padding: 1rem;
}

/* Aside */
aside {
  position: fixed;
  width: 30rem;
  height: 100%;
  background-color: #efefef;
  z-index: 1000;
  transform: translateX(-30rem);
  transition: all 0.5s;
}
aside.open {
  transform: translateX(0);
}

button.open-sidebar {
  font-size: 3rem;
  padding: 0.02rem 0.05rem;
  margin: 0 0.5rem;
  background: none;
  color: #ffffff;
  cursor: pointer;
}

button.open-sidebar:hover {
  border-color: #ffffff;
}
aside ul {
  padding: 0;
  list-style: none;
}

aside li {
  display: flex;
  justify-content: space-between;
  padding: 1rem;
}

button.close-sidebar {
  padding: 0.3rem 0.8rem;
}
/*Images */
img {
  border-radius: 0.5rem;
}
/* .img.medium */
.medium {
  /* max-width: 30rem; */
  width: 100%;
}

.img.large {
  width: 100%;
}

/* Card */

.card {
  border-radius: 1rem;
  margin: 1rem;
}

.dashboard-card {
  border: 0.1rem;
  border-top: grey solid;
  margin-top: 0.5rem;
  margin-bottom: 0;
  background-color: none;
  /* border-radius: 0.5rem; */
  margin: 1rem;
}

.dashboard-card-body {
  padding: 1rem;
}
.dispensary_info_card {
  border-radius: 0.5rem;
  margin: 1rem;
  text-align: center;
}
.dispensary_info_card_body {
  padding: 1rem;
}

.dispensary_info_card > * {
  margin-bottom: 0.5rem;
}

.card-category {
  border-radius: 1.5rem;
  margin: 1rem;
}
.row-sections {
  margin: 0 -5px;
}

/* @media screen and (max-width: 600px) {
  .column {
    width: 100%;
    display: block;
    margin-bottom: 20px;
  }
} */

.row-sections:after {
  content: "";
  display: table;
  clear: both;
}
.column {
  float: left;
  width: 33%;
  /* padding: 0 10px; */
}

#bipoc {
  background-color: #babc70;
}

#woman_owned {
  background-color: #7989dc;
}
#lgbt_owned {
  background-color: grey;
  /* height:30rem;
  width:45rem; */
}
#equity_verified {
  background-color: yellow;
}
#sustainably_sourced {
  background-color: #20652fff;
  /* height:30rem;
  width:45rem  */
}
#worker_owned_coop {
  background-color: rgb(216, 161, 89);
  /* height:30rem;
  width:45rem  */
}
#shop_thc {
  background-color: grey;
}
#shop_cbd {
  background-color: grey;
}
#shop_all {
  background-color: grey;
}

.card-category > h1 {
  color: white;
  /* font-family: Bahnscrift; */
}
.checkout-card {
  border: 0.1rem grey solid;
  background-color: whitesmoke;
  border-radius: 0.5rem;
  margin: 1rem;
}

.checkout-card-body {
  padding: 1rem;
}
.card-body {
  padding: 1rem 0 0 0;
  background-color: rgba(255, 255, 255, 0.274);
  border-radius: 0.5rem;
}

.card-body > * {
  margin-bottom: 0.5rem;
}

/* .price {
  font-size: 2rem;
} */

/* Rating */

.rating span {
  color: #f0c040;
  margin: 0.1rem;
  font-size: 1.25rem;
}

.rating span:last-child {
  color: black;
}

/* Alert */

.loading {
  display: block !important;
}
.success {
  color: #20a020;
}

.danger {
  color: #a02020;
}

.alert {
  padding: 1rem;
  border: 0.1rem solid transparent;
  border-radius: 0.5rem;
}

.alert-info {
  color: #2020a0;
  background-color: #e0e0ff;
}

.alert-danger {
  color: #a02020;
  background-color: #ffe0e0e0;
}
.alert-success {
  color: #20a020;
  background-color: #eeffe0;
}

/* Form */

.form {
  max-width: 60rem;
  margin: 0 auto;
}

.form > div {
  display: flex;
  flex-direction: column;
  margin: 1rem;
}

.form label {
  margin: 1rem 0;
}

/* dropdown*/

.dropdown {
  display: inline-block;
  position: relative;
}

.dropdown-content {
  position: absolute;
  display: none;
  right: 0;
  min-width: 12rem;
  padding: 1rem;
  z-index: 1;
  background-color: #d89947;
  margin: 0;
  margin-top: 0.4rem;
  border-radius: 0.5rem;
}

.dropdown:hover .dropdown-content {
  display: block;
}

/* Checkout */

.checkout-steps > div {
  border-top: 0.3rem #c0c0c0 solid;
  color: #c0c0c0;
  flex: 1;
  padding: 1rem;
  font-weight: bold;
  text-align: center;
}

.checkout-steps > div.active {
  border-top-color: #f08000;
  color: #f08000;
}

/* Table */

.table {
  width: 100%;
  border-collapse: collapse;
}

.table tbody tr:nth-of-type(odd) {
  background-color: #f4f4f4;
}

.table td,
.table th {
  text-align: left;
  border: 0.1rem solid #e4e4e4;
  padding: 0.5rem;
}

.table-event td,
.table-event th {
  text-align: left;
  border: 0.1rem;
  padding: 0.5rem;
}
.table button {
  margin: 0 0.2rem;
}

/* Search */

.search-button {
  border-radius: 0 0.5rem 0.5rem 0;
  border-right: none;
  margin-left: 0.5rem;
}

.search-input {
  border-radius: 0.5rem 0 0 0.5rem;
  border-right: none;
  margin-left: 0.5rem;
}
.active {
  font-weight: bold;
}

/* Pagination */

.pagination {
  display: flex;
  align-items: center;
  justify-content: center;
}

.paginationItem {
  background: #fff;
  border: 2px solid #666;
  padding: 10px 15px;
  border-radius: 50%;
  height: 45px;
  width: 45px;
  position: relative;
  margin: 0 5px;
  cursor: pointer;
}

.paginationItem span {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.prev,
.next {
  background: #fff;
  border: none;
  padding: 10px;
  color: blue;
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.4);
  margin: 0 10px;
  cursor: pointer;
}

.paginationItem.active {
  border: 1px solid #888;
  color: #888;
  pointer-events: none;
}

.prev.disabled,
.next.disabled {
  pointer-events: none;
  box-shadow: none;
  color: #999;
}
/* 
 .pagination a {
  padding: 1rem;
  margin: 0.5rem;
  border-radius: 0.5rem;
  border: 0.1rem #a4a4a4 solid;
  font-size: 1.6rem;
  font-family: Arial, Helvetica, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #f8f8ff;
  cursor: pointer;
} */
/*
.pagination a.active {
  font-weight: bold;
} */
/* Badge */
.badge {
  background-color: transparent;
  padding-bottom: 0;
  margin-bottom: 0;
  margin-left: 0;
  padding-left: 0;
  padding-top: 0.5rem;
}
.badge img {
  height: 5rem;
  width: 5rem;
  padding-bottom: 0;
  margin-bottom: 0;
}
.row-badges {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  /* justify-content: space-between; */
  align-items: center;
}
.signup-checkbox {
  display: flexP;
  align-content: center;
}

.badge_image {
  height: 17rem;
  width: 17rem;
  padding: 5rem;
}
.badge_company_description {
  padding-left: 20%;
  padding-right: 20%;
}

.badge_company_description > p {
  height: 100%;
  width: 100%;
  padding: 4rem;
  padding-top: 6rem;
}

/* @media screen and (max-width: 600px) {
  .badge_company_description {
    padding-left: 10%;
    padding-right: 10%;
  }
} */
.disclaimer {
  font-size: xx-small;
  color: gray;
}

.storefront {
  /* justify-content: center; */
  padding-top: 6rem;
  height: 12rem;
  width: 12rem;
}
.company_badge_container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
}

/* Budtender */

.text-center {
  text-align: center;
}

.budtender-button {
  align-self: center;
  background-color: rgb(40, 102, 218);
  color: white;
}

/* cannabis administration window info */

#cannabis_administration_info {
  /* position: absolute; */
  /* left: 50%;
  top: 50%;
  transform: translate(-50%, -50%); */

  /* width: 40%;
  height: 50%; */
  font-size: 1.5rem;
  padding: 10%;
  text-align: center;
}

.info-image {
  width: 8rem;
  height: 8rem;
}

.browse-link {
  font-size: 10pt;
  display: flex;
  justify-content: right;
}

.dispnesary-banner {
  max-width: 60%;
  margin: 0 auto;
}
.mission-header {
  font-size: 5rem;
  text-align: center;
}
.dispensary-header {
  font-size: 5rem;
  text-align: center;
}
.homepage-header {
  font-size: 4.5rem;
  text-align: center;
}

#shop {
  text-align: center;
  font-size: 40px;
  font-weight: 400;
  margin: 10px;
  margin-top: 20px;
  margin-left: 30px;
  margin-right: 30px;
  border-top: rgba(156, 155, 155, 0.959) solid 2px;
}
/* HERERERER */
.shop-row {
  width: 100%;
  height: auto;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.category-row {
  /* width: 100%; */
  height: auto;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  flex-direction: row;
}
.shop-image {
  position: relative;

  width: 460px;
  height: 300px;
  margin: 20px;
  overflow: hidden;
  border-radius: 5px;
}

.dispensary-image {
  position: relative;
  flex: 1;
  max-width: 460px;
  height: 320px;
  margin: 20px;
  padding-top: 2rem;
  /* overflow: hidden; */
  border-radius: 5px;
  /* background-color: #D3428F; */
  text-align: center;
  /* width: 200px;
  height: 200px;
  border-radius: 50%; */
}

.team-card {
  position: relative;
  flex: 1;
  max-width: 400px;
  height: 400px;
  margin: 20px;
  overflow: hidden;
  border-radius: 5px;
  /* border:1px black solid; */
  background-color: transparent;
}
/* 
.dispensary-image  img {
  width:30rem;
  height:auto;
  vertical-align: top;
  padding-top: 2rem;
  overflow: hidden;
  padding-left: 17rem;
 
  padding-bottom: 2rem;
  transition: 0.6s;
  transition-property: opacity;
} */

.shop-image img {
  opacity: 0.8;
  position: relative;
  vertical-align: top;
  transition: 0.6s;
  transition-property: opacity;
}

.education-resource-image img {
  opacity: 0.8;
  position: relative;
  vertical-align: top;
  transition: 0.6s;
  transition-property: opacity;
}

.shop-image:hover img {
  opacity: 1;
}

.education-resource-image:hover img {
  opacity: 1;
}

.shop-image .details {
  z-index: 1;
  position: absolute;
  top: 0;
  right: 0;
  color: #fff;
  width: 100%;
  height: 100%;
}

.education-resource-image .details {
  z-index: 1;
  position: absolute;
  top: 0;
  right: 0;
  color: #fff;
  width: 100%;
  height: 100%;
}

.dispensary-image .details {
  z-index: 1;
  position: absolute;
  top: 0;
  right: 0;
  color: #fff;
  width: 100%;
  height: 100%;
}

#worker_owned_coop img {
  width: 13rem;
  height: 13rem;
  vertical-align: top;
  padding-left: 17rem;
  padding-top: 10rem;
  padding-bottom: 0.5rem;
  /* transition: 0.6s;
  transition-property: transform; */
}
#bipoc img {
  width: 28rem;
  height: auto;
  vertical-align: top;
  padding-top: 0rem;
  margin-top: 0;

  overflow: hidden;
}

#woman_owned img {
  width: 30rem;
  height: auto;
  vertical-align: top;
  padding-top: 5.5rem;
  padding-left: 2rem;
  overflow: hidden;
}

#merp-white {
  border: 2.5px solid rgba(155, 153, 153, 0.411);
  background-color: white;
}

#merp-white .details {
  color: black;
}

#thc .details {
  color: black;
}

#thc {
  background-color: #ffd700;
}
/* #bipoc {
  background-color: #000000ff;
} */

/* #woman_owned {
  background-color: #6b2965ff;
} */
#lgbt_owned .details {
  color: black;
  /* height:30rem;
  width:45rem; */
}
#equity_verified {
  background-color: #ffd700;
}

#equity_verified h2 {
  color: black;
}
#equity_verified .details {
  color: black;
}
#sustainably_sourced {
  background-color: #20652fff;
}

#budtender-button {
  font-size: 3rem;
}

#disabled_veteran {
  background-color: #d40000;
}

.shop-image .details > h2 {
  text-align: center;
  font-size: 35px;
  text-transform: uppercase;
  font-weight: 300;
  /* margin-top: 70px; */
  margin-top: 45px;
  /* transition: 0.4s;
  transition-property: transform; */
}

.education-resource-image .details > h2 {
  text-align: center;
  font-size: 35px;
  text-transform: uppercase;
  font-weight: 300;
  /* margin-top: 70px; */
  margin-top: 45px;
  /* transition: 0.4s;
  transition-property: transform; */
}

.shop-image .details h2 span {
  font-weight: 900;
}

.education-resource-image .details h2 span {
  font-weight: 900;
}

.dispensary-image .details > h2 {
  text-align: center;
  font-size: 35px;
  text-transform: uppercase;
  font-weight: 300;
  /* margin-top: 70px; */
  margin-top: 45px;
  /* transition: 0.4s;
  transition-property: transform; */
}

.dispensary-image .details h2 span {
  font-weight: 900;
}

/* .shop-image:hover .details h2 {
  transform: translateY(-30px);
} */

.shop-image .details p {
  margin: 30px 30px 0 30px;
  font-size: 10px;
  font-weight: 600;
  text-align: center;
  opacity: 0;
  transition: 0.6s;
  transition-property: opacity, transform;
}

.shop-image:hover .details p {
  opacity: 1;
  transform: translateY(-40px);
}

.dispensary-image .details p {
  margin: 30px 30px 0 30px;
  font-size: 10px;
  font-weight: 600;
  text-align: center;
  opacity: 0;
  transition: 0.6s;
  transition-property: opacity, transform;
}

.dispensary-image:hover .details p {
  opacity: 1;
  transform: translateY(-40px);
}

/* .shop-image:hover img {
  opacity: 1;
  transform: translateY(-40px);
} */

.more {
  position: absolute;
  background: rgba(255, 255, 255, 0.8);
  width: 100%;
  display: flex;
  justify-content: start;
  align-items: center;
  padding: 15px;
  bottom: -60px;
  transition: 0.6s;
  transition-property: bottom;
}

.shop-image:hover .more {
  bottom: 0;
  font-weight: 900;
}

/* Socials buttons */

.social-media h2 a {
  color: #ffce1d;
}

.social-logo {
  /* height: 30px;
  width: 50px; */
  height: 1.5rem;
  width: 3.5rem;
}
.social-media-icon {
  padding: 0px 2px 0px 2px;
  height: auto;
  width: auto;
}

/* Block Paragraphs */

.inline-block {
  position: relative;
  margin-left: 40px;
  margin-right: 40px;
  /* display: inline-block;
  position: relative; */
}

.inline-block h1 {
  text-align: center;
}

/* Team member CSS */

.members {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

/* rewards graphic */

#rewards-img {
  height: 2rem;
  width: 2rem;
  margin-left: 5px;
  padding-bottom: 0;
  margin-bottom: 0;
}

.rewards {
  display: flex;
  justify-content: center;
}

/* Rewards */
#rewards-details h2 {
  text-align: center;
}

#rewards-details p {
  text-align: center;
}

#rewards-available h2 {
  text-align: center;
}

.reward-icon {
  height: 10rem;
  width: 10rem;
}

/* Graphs */

.graph-row {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-bottom: 1.25rem;
  justify-content: space-around;
}

.verify-row {
  display: flex;
  flex-wrap: nowrap;
  /* align-items: center; */
  margin-bottom: 1.25rem;
}

.verify-row button {
  margin: 2rem;
}

.carousel-outer .carousel .slide {
  background: none;
}

.carousel .slide img {
  max-width: 30rem;
}
.car {
  height: 20rem;
  width: 100rem;
}

/* Events */
.event {
  border: solid 1.5px rgb(192, 182, 182);
  border-radius: 1rem;
}

.event-details {
  margin: 2.5rem;
}

.product-info-link {
  font-size: 1.5rem;
}

.checkbox_section {
  display: flex;
  align-items: center;
}
.checkbox_section > label {
  margin-left: 0.5rem;
}
.register-title {
  font-size: 2rem;
  font-family: "Sora", "serif";
}

.brand_list_subsection_main {
  text-align: center;
}

.brand_list_subsection {
  text-align: center;
  margin-top: 2rem;
}

.brand_list_main_header {
  font-size: 2.5rem;
  font-family: "Oswald", serif;
}

.brand_list_bullets {
  text-align: left;
  font-size: 1.25rem;
  padding: 1rem 0 1rem 0;
}
.brand_list_section {
  font-size: 1.25rem;
}
.brand_list_text {
  font-size: 1.5rem;
}
.education-resource-image {
  position: relative;
  /* flex: 1; */
  /* max-width: 460px;
  height: 300px; */
  margin: 20px;
  overflow: hidden;
  /* border-radius: 5px;
  background: #f00; */
  width: 20rem;
  height: 20rem;
  border-radius: 50%;
}
