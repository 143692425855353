

.numbered-list{
    padding-left: 5rem;
}
.terms-of-use-header{
    text-align: center;
    font-size: 4rem;
    font-weight: bold;
}

.addendum{
    margin:1.5rem
}

.addendum-header{
    font-size: 2.5rem;
}
.sub-section{
    margin:1.5rem;
}

.sub-header{
    font-size: 2.75rem;
    font-weight: bold;
}

@media(min-width:350px){
    .terms-container{
        margin:1rem
    }  
}