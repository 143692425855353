.brand_card {
  display: flex;
  flex-direction: row;
  /* justify-content: space-between; */
  background-color: rgb(255, 253, 237, 0.55);
  border: 1px solid rgb(0, 0, 0, 0.15);
  border-radius: 10px;

  height: 130px;
  width: 424px;
  margin: 4rem;
}

.brand_card_category {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 0.5rem;
  /* margin-top: 0.5rem; */
}
.button {
  background: none;
  cursor: pointer;
  transition: background 0.5s ease;
}

.active#heart:before,
.active#heart:after {
  background: red !important;
}
#heart {
  transition: background 0.5s ease;
}
#heart:before,
#heart:after {
  transition: background 0.5s ease;

  transform: rotate(-45deg);
  transform-origin: 0 100%;
}
#heart:after {
  left: 0;
  transform: rotate(45deg);
  transform-origin: 100% 100%;
}

.brand_category {
  width: 3rem;
  height: 3rem;
}

button.liked {
  color: black;
}
button.liked i {
  animation: anim 0.5s ease-in-out;
  -webkit-animation: anim 0.5s ease-in-out;
}

.more-info {
  display: flex;
  align-items: center;
  flex-direction: row;
  font-size: 1.25rem;
}

.brand_card_img {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: white;
  width: 132px;
  height: 128px;
  border-radius: 10px;
}

.brand_card_text {
  display: flex;
  /* justify-content: center; */
  align-items: center;
  padding-left: 2rem;
}

.brand_card_title {
  display: flex;
  align-items: center;
  flex-direction: row;
}
.brand_card_name {
  font-family: "Oswald", sans-serif;
  font-size: 1.75rem;
  font-weight: 400;
}

.brand_card_title > img {
  padding-left: 0.5rem;
  height: 1.75rem;
  width: 1.75rem;
}

.brand_card_tags {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 0.5rem;
}

.tag {
  margin-right: 0.25rem;
}

.brand_card_likes {
  font-size: 1.25rem;
  font-weight: bold;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.brand_card_likes > img {
  height: 2rem;
  width: 2rem;
  margin-right: 0.5rem;
}

.xtra_tags {
  background-color: #d9d9d9;
  border-radius: 18px;

  padding: 0.65rem;
  font-weight: bold;
}

.brand_category_section {
  display: flex;
}

.brand-card-header {
  display: flex;
  justify-content: space-between;
}

.brand_card_title {
  margin-top: 1.5rem;
}

@media (min-width: 250px) {
  .brand_card {
    height: 110px;
    width: 225px;
  }
  .brand-screen-title {
    font-size: 2rem;
  }

  .brand_page_description {
    font-size: 1.5rem;
  }

  .brand_page_categories {
    font-size: 1.75rem;
  }
  .brand_card_tags {
    flex-wrap: wrap;
  }

  .brand_card_img {
    height: 108px;
    width: 125px;
    border-radius: 0;
    padding: 0 5px 0 5px;
  }
  /* .tag > img {
    width: 60px;
  } */

  .brand_card_text {
    display: flex;
    /* justify-content: center; */
    align-items: center;
    padding-left: 0.75rem;
  }
  .brand_card_name {
    font-size: 1.25rem;
  }
  .more-info {
    font-size: 1rem;
  }
  .brand_category {
    width: 2.75rem;
    height: 2.75rem;
  }
  .brand_category_screen {
    width: 2.75rem;
    height: 2.75rem;
  }
  .brand_category_screen > img {
    width: 2.75rem;
    height: 2.75rem;
  }
  .brand_card_likes > img {
    width: 1.5rem;
    margin-right: 0.25rem;
  }
  .right_card {
    width: 150px;
  }
  .brand-card-header {
    width: 285px;
  }
}

@media (min-width: 350px) {
  .brand_card {
    height: 110px;
    width: 325px;
  }

  .brand_card_img {
    height: 108px;
    width: 125px;
    border-radius: 0;
    padding: 0 5px 0 5px;
  }
  /* .right_card {
    width: unset;
  } */

  .brand_card_text {
    display: flex;
    /* justify-content: center; */
    align-items: center;
    padding-left: 0.75rem;
  }
  .brand_card_name {
    font-size: 1.75rem;
  }
  .more-info {
    font-size: 1.25rem;
  }
  .brand_category {
    width: 2.75rem;
    height: 2.75rem;
  }
  .brand-card-header {
    width: unset;
  }
}

@media (min-width: 400px) {
  .brand_card {
    height: 110px;
    width: 390px;
    margin: 2rem;
  }

  .brand_card_likes {
    margin-top: 0.5rem;
  }

  .brand_card_text {
    display: flex;
    /* justify-content: center; */
    align-items: center;
    padding-left: 0.75rem;
  }
  .brand_card_name {
    font-size: 1.75rem;
  }
  .more-info {
    font-size: 1.5rem;
  }
}

@media (min-width: 700px) {
  .brand_card {
    height: 150px;
    width: 500px;
    margin: 2rem;
  }

  .brand_card_img {
    height: 148px;
    width: 140px;
  }

  .right_card {
    width: 359px;
  }

  .brand_card_name {
    font-size: 2.5rem;
  }

  .brand_category {
    height: 4rem;
    width: 4rem;
  }

  .xtra_tags {
    border-radius: 20px;
    padding: 0.9rem;
    font-size: 1.5rem;
  }
  .brand-screen-title {
    font-size: 3rem;
  }

  .brand_page_categories {
    font-size: 2rem;
  }
  .brand_category_screen > img {
    width: 3.5rem;
    height: 3.5rem;
  }
  .brand_category_screen {
    width: 3.5rem;
    height: 3.5rem;
  }
}
