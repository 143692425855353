.pagination a {
  margin: 1rem;
  font-weight: bold;
  font-size: 1.25rem;
}

.active  {
   text-decoration: underline;
  }

  .button-search{
    background-color: #5067db;
    color:white;
    font-weight: bold;
    border: solid transparent
  }

  .search-header-title{
    font-size: 3.5rem;
    text-align: center;
    font-family: "Oswald", sans-serif;
    /* font-family: 'Epilogue', sans-serif; */
  }